import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import React, { useMemo, useState } from "react"
import { useEffect } from "react"
import "./QuoteSection.scss"

export type Quote = {
  mainPartOfQuote: string
  restOfQuote: string
  quoteBy?: string
  position?: string
  companyName?:
    | "Roger"
    | "Twisto"
    | "ThreatMark"
    | "Payoneer"
    | "Roger"
    | "Habito"
}
interface QuoteSectionProps {
  quotes: Quote[]
  className?: string
}

const QuoteSection = ({ quotes, className }: QuoteSectionProps) => {
  const [quoteIdx, setQuoteIdx] = useState(0)

  const images = useStaticQuery(graphql`
    query {
      images: allFile(filter: { dir: { regex: "/companyLogos/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(quality: 100, width: 400)
            }
          }
        }
      }
    }
  `)

  const match = useMemo(
    () =>
      images.images.edges.find(({ node }: any) =>
        node.relativePath.includes(quotes[quoteIdx]?.companyName?.toLowerCase())
      ),
    [quoteIdx, images]
  )

  let timeout: any
  useEffect(() => {
    timeout = setTimeout(() => {
      setQuoteIdx(prev => (prev + 1 < quotes.length ? prev + 1 : 0))
    }, 20000)
  }, [quoteIdx, setQuoteIdx])

  const handleDotClick = (index: number) => {
    window.clearTimeout(timeout)
    setQuoteIdx(index)
  }

  return (
    <div className={`QuoteSection ${className}`}>

      <div className="QuoteSection__wrapper">

        <div>
          <h2>What Our <span className="highlighted">Customers Are Saying</span></h2>
        </div>

        <div className="w-wrap QuoteSection__element">
          <div className="w1-2">
            <div className="QuoteSection__rest">
                {quotes[quoteIdx]?.restOfQuote}
            </div>

            <div className="QuoteSection__switch">
                {quotes.length > 1 &&
                  quotes.map((_, idx) => (
                    <div
                      key={idx}
                      onClick={() => handleDotClick(idx)}
                      style={{ cursor: "pointer" }}
                    >
                      {quoteIdx === idx ? (
                        <svg height="24" width="24">
                          <circle
                            cx="10"
                            cy="10"
                            r="8"
                            fill="black"
                            className="QuoteSection__selected"
                            stroke="#2993d2"
                          />
                        </svg>
                      ) : (
                        <svg height="24" width="24">
                          <circle cx="10" cy="10" r="8" fill="white" stroke="#2993d2" />
                        </svg>
                      )}
                    </div>
                  ))}
              </div>
          </div>

          <div className="w1-2">
            <div className="QuoteSection__by__image">
                {match && (
                  <GatsbyImage
                    alt="Testimonial"
                    image={match.node.childImageSharp.gatsbyImageData}
                  />
                )}
            </div>
            <div className="QuoteSection__info">

            <div className="QuoteSection__by">
                <h3 className="QuoteSection__by__name">
                  {quotes[quoteIdx]?.quoteBy}
                </h3>
                <p className="QuoteSection__by__company">
                  {quotes[quoteIdx]?.position}&nbsp;|&nbsp;<a href={quotes[quoteIdx]?.link}>{quotes[quoteIdx]?.companyName}</a>
                </p>

            </div>
          </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default QuoteSection
