import { Link } from "gatsby"
import React from "react"
import Button from "../../components/Button/Button"
import Columns from "../../components/Columns/Columns"
import Layout from "../../components/Layout/Layout"
import MainSection from "../../components/MainSection/MainSection"
import Section from "../../components/Section/Section"
import Seo from "../../components/Seo/Seo"
import plus from "../../svg/ico_plus.svg"

import QuoteSection from "../../components/QuoteSection/QuoteSection"
import "../../styles/aml.scss"

import alert from "../../svg/AlertPriority.svg"
import optimize from "../../svg/adaptive.svg"
import identify from "../../svg/HuntUnexpected.svg"
import add from "../../svg/Augment.svg"

import ico_aml1 from "../../images/ico_aml1.png"
import ico_aml2 from "../../images/ico_aml2.png"
import ico_aml3 from "../../images/ico_aml3.png"
import ico_aml4 from "../../images/ico_aml4.png"
import AmlTheme1 from "../../images/aml_theme1.jpg"
import AmlTheme2 from "../../images/aml_theme2.png"
import AmlTheme3 from "../../images/aml_theme3.png"
import AmlTheme4 from "../../images/aml_theme4.png"
import AmlTheme5 from "../../images/aml_theme5.png"
import CaseStudy1 from "../../images/caseStudy1.png"
import CaseStudy2 from "../../images/caseStudy2.png"
import Documents from "../../images/ilustrations/Documents.png"
import AmlTheme from "../../images/ilustrations/AmlTheme.svg"
import Card from "../../components/Card/Card"
import TextWithIcon from "../../components/TextWithIcon/TextWithIcon"

const Aml = () => {
  return (
    <Layout>
      <Seo
        title="Supercharge your Anti-Money-Laundering Efforts"
        description="Resistant AI analyses the hidden relationships between identities and transactions to make calls between legitimate and criminal activities more precise."
      />

   <MainSection
     caption="AML Optimization"
     title="Stop chasing ghosts. Start "
     titleHighlight="Chasing crooks."
     text="Limit your exposure, cut down your backlog, and find new risks with practical Al for your existing AML transaction monitoring system."
     className="aml__main aml__main__new"
     divided
     showReferences
     imageLink={AmlTheme}
     button={{
       link: "/contact",
       variant: "blue",
       text: "Get started",
       size: "large",
     }}
   ></MainSection>

      <Section id="aml-challenges">

      <div className="w-wrap wHeadRow">
        <div className="w1-3">
          <h2><span className="highlighted">AI Superpower</span> for AML Analysts</h2>
        </div>
        <div className="w2-3">
          <div>
          <p className="justified">
            Safely scale your business without retraining your anti-money laundering analysts. Resistant Al's Transaction Forensics boosts your investigatory productivity, cuts the noise from rules-based transaction monitoring systems, and reveals the real risk lurking in your data - all in total compliance.
          </p>
          </div>
        </div>
      </div>

        <div className="w-wrapCards w-wrap">

            <div className="w1-4 wCard wCardNew">
              <img alt="AML Challenges" src={ico_aml1}/>
              <h3>
                <span className="highlighted">80% reduction</span>
              </h3>
              <h3>in false positives</h3>
            </div>

            <div className="w1-4 wCard wCardNew">
              <img alt="AML Challenges" src={ico_aml2}/>
              <h3>
                <span className="highlighted">200% increase</span>
              </h3>
              <h3>in productive alerts</h3>
            </div>

            <div className="w1-4 wCard wCardNew">
              <img alt="AML Challenges" src={ico_aml3}/>
              <h3>
                <span className="highlighted">25% SARS to</span>
              </h3>
              <h3>productive alert</h3>
            </div>

            <div className="w1-4 wCard wCardNew">
              <img alt="AML Challenges" src={ico_aml4}/>
              <h3>
                <span className="highlighted">Deploy in weeks</span>
              </h3>
              <h3>not months</h3>
            </div>
        </div>
      </Section>

      <Section sectionClass=" aml__principles">
          <div className="w-wrap">
            <div className="w1-2">
              <img src={AmlTheme1} />
            </div>
            <div className="w1-2">
              <h2>Limit your exposure <span className="highlighted">before transaction happen</span></h2>
              <p>
                Detect anomalous behaviors in the transaction stream to keep criminal activity - whether fraud or money laundering - out of your network and see how manageable investigations become when crime is stopped at placement or first layering.
              </p>
            </div>
          </div>
      </Section>

      <Section sectionClass=" aml__principles aml__reverse">
          <div className="w-wrap">
            <div className="w1-2">
              <h2>Categorize. Prioritize. <span className="highlighted">Contextualize.</span></h2>
              <p>
                Stop burning out your best investigators on false alerts. See how quickly your team can cut through the riskiest items in your backlog by using Al to prioritize all incoming alerts and provide far more context into each suspicious event.
              </p>
            </div>

              <div className="w1-2">
                <img src={AmlTheme2} />
              </div>
          </div>
      </Section>

      <Section sectionClass="aml__dotsbg aml__principles">
          <div className="w-wrap">
            <div className="w1-2">
              <img src={AmlTheme3} />
            </div>
            <div className="w1-2">
              <h2><span className="highlighted">Find </span> What You've Been <span className="highlighted">missing.</span></h2>
              <p>
                Building static rules for every scenario won't let you keep up with evasive criminals, but it will eat up your time maintaining them. Your data already has everything you need to find your unknown risks. Just segment your customers dynamically based on identities, behaviors, and even weaker data points to make outliers stick out like statistical sore thumbs.
              </p>
            </div>
          </div>
      </Section>

      <Section sectionClass="aml__dotsbg aml__reverse aml__principles">
          <div className="w-wrap">
            <div className="w1-2">
              <h2>Pinpoint where the <span className="highlighted">noise is coming from.</span></h2>
              <p>
                If fire alarms failed 95% of the time, no one would claim they work. So why would you tolerate a transaction monitoring system that generates 20 times more noise than real findings? Identify underperforming rules so you can fix them or take them offline.
              </p>
            </div>

              <div className="w1-2">
                <img src={AmlTheme4} />
              </div>
          </div>
      </Section>

      <Section sectionClass="aml__dotsbg aml__reverse aml__principles">
          <div className="w-wrap">
            <div className="w1-2">
              <h2>The system you know - better, faster, smarter... <span className="highlighted">and more explainable.</span></h2>
              <p>
                 Switching costs take on a whole new dimension when you're talking about compliance tech. Why go through the pain? Enha your existing AML transaction monitoring system with ready-bu ensembles of smaller, focused models that deploy within days, keep your maintenance costs low, and can be understood.
              </p>
            </div>

              <div className="w1-2">
                <img src={AmlTheme5} />
              </div>
          </div>
      </Section>

      {/*<Section sectionClass=" aml__principles">
          <div className="w-wrap">
              <h2>Chosen by <span className="highlighted">forward looking</span> MLROs</h2>
          </div>
      </Section>*/}

      <QuoteSection
        className="Homepage__quote"
        quotes={[
          {
            restOfQuote:
              "Resistant AI dramatically reduces false positives and detects advanced fraud and manipulation at the same time. Under the protection of Resistant AI, our Nikita engine can fully focus on credit risk assessment excellence.",
            quoteBy: "Michal Krocil",
            companyName: "Twisto",
            position: "Chief Risk Officer",
            link: "https://www.twisto.cz/",
          },
          {
            restOfQuote:
              "We use Resistant AI’s technology in our KYC process. We greatly value the insights gained to help us validate the authenticity of documents submitted by customers.",
            quoteBy: "Keren Levy",
            companyName: "Payoneer",
            position: "COO",
            link: "https://www.payoneer.com",
          },
          {
            restOfQuote:
              "Resistant AI gives us reliable, real-time insights into our customer data. With high degrees of precision and accuracy.",
            quoteBy: "Dan Hagerty",
            companyName: "Habito",
            position: "CEO - Founder",
            link: "https://www.habito.com/",
          },
          {
            restOfQuote:
              "Resistant AI prevents the manipulation of invoices submitted to our marketplace. It allows our investors to trade in security and saves my team a huge amount of manual work.",
            quoteBy: "Alexandra Belkova",
            companyName: "Roger",
            position: "Head of Operations",
            link: "https://www.roger.cz",
          },
        ]}
      ></QuoteSection>
{/*
      <Section sectionClass="aml__caseStudies">
          <div className="w-wrap">
            <div className="w1-2 aml__caseStudies__card">
              <img src={CaseStudy1} alt="Case study" />
              <div className="aml__caseStudies__card__text">
                <h3>Case study 1</h3>
                <p>
                   There's no bait and switch with Resistant Al-when we say real-time Al,
                   we mean our full capabilities, not say real-tin rules-based systems.
                </p>
              </div>
            </div>

            <div className="w1-2 aml__caseStudies__card">
              <img src={CaseStudy2} alt="Case study" />
              <div className="aml__caseStudies__card__text">
                <h3>Case study 1</h3>
                <p>
                   There's no bait and switch with Resistant Al-when we say real-time Al,
                   we mean our full capabilities, not say real-tin rules-based systems.
                </p>
              </div>
            </div>
          </div>
      </Section>
*/}
    </Layout>
  )
}

export default Aml
